// ============ Shared ============ //
.green {
    color: $green-primary;
}

.red {
    color: $red-primary
}

.orange {
    color: $orange-primary;
}

.lightgrey {
    color: $lightGrey;
}
.text-bold {
    font-weight: $bold;
}
 
.mb-large {
    margin-bottom: $margin-large;
}

.ml-small {
    margin-left: $margin-small;
}

.clickable {
    cursor: pointer;
}

.desactivated {
    opacity: 0.1;
}

.mb-40 {
    margin-bottom: 40px;
}

.mt-large {
    margin-top: $margin-large;
}

.blue-azure {
    color: $blue-azure;
}

.bg-white {
    background: $white-primary;
}

.bg-white-light {
    background: $white-light;
}

.position-absolute {
    position: absolute;
}

.align-center {
    text-align: center;
}

.button-reset {
    @include button-reset;
}

.title-dark-default {
    @include text-style-card('title-default', $black);
}

.margin-bottom-medium {
    margin-bottom: $margin-medium;
}

.margin-left-medium {
    margin-left: $margin-medium;
}

.title-default {
    @include text-style('text-default');
}

.inline-block-middle {
    @include inline-block-middle;
}

.next-btn-step,
.back-btn-step {
    @include position(absolute, $bottom: 30px);

    &:disabled {
        background-color: transparent;
    }
 
    &.next-btn-step {
        right: 20px;
    }
 
    &.back-btn-step {
        left: 20px;
    }
}

.tag {
    max-width: 150px;
    text-overflow: ellipsis;
    overflow: hidden;
}

.record-tag-select {
    max-width: 120px;
}

.notAuthorizedAccess {
    @include position(absolute, $top: 50%, $left: 50%);
    transform: translate(-50%, -50%);
}

.mobile-details-drawer {
    .ant-drawer-header {
        padding: 2px $padding-medium;
    }

    .ant-drawer-body {
        @include flex-layout($justify: space-between);
        align-items: flex-start;
        padding: 8px 10px;
    }
}

.header-drawer {
    @include flex-layout($justify: space-between, $align: center);
}

.footer {
    &-leak-bg {
        @include box(100%);
    }

    &-leak-logo {
        @include position(absolute, $top: 50%, $right: 6%);
        transform: translateY(-50%);
        width: 100px;
        object-fit: contain;
    }
}

.leaflet-container {
    @include box(50%, 100%);
    border-radius: 2rem;

    .leaflet-coordinate {
      margin: 0;
    }

    .leaflet-control-attribution {
        display: none;
    }
}

.synthesis-charts {
    @include flex-layout('flex-center');
    flex-wrap: wrap;
    margin-top: $margin-large;
    gap: 30px;
}

.synthesis-cards {
    @include flex-layout($justify: space-around);
    margin-top: $margin-large;
    gap: 15px;
}

.sounds {
    margin: $margin-large 0;
}

.sounds-list-options {
    padding: 0 $padding-default;
}

.update-data {
    text-align: end;
}

.hidden-info-sounds {
    margin: $margin-large;

    svg {
        margin-right: $margin-medium;
    }
}

.mobile-infos-title {
    @include flex-layout($justify: space-between);
    padding-right: $padding-large;
}

.info-sounds-btn {
    border: none;
    background-color: transparent;
}

.come-back {
    @include text-style('icon-medium');
    width: 20px;
    margin: $margin-large;
    transition: .3s ease;

    &:hover {
        color: $blue-light;
    }
}

.form-container {
    .settings-form {
        width: 400px;
        margin: auto;
        text-align: center;
        box-shadow: $box-shadow-form;
        border-radius: $border-radius-small;
        padding: $padding-default;
    }

    .step-form {
        margin: 0;
    }

    .input-add-to-list {
        margin-top: $margin-large;
        width: 81%;
    }

    .btn-add-to-list {
        border-radius: 0px 5px 5px 0px;
        padding: 0px 10px;
    }
}

.mini-map {
    @include box(150px);
    margin: auto;
    box-shadow: 6px 6px 9px -2px $grey-blue;
}


.map-record-control {
    position: absolute;
    right: 15px;
    z-index: 1000;
    width: 300px;
    padding: 10px;
    background-color: rgba(255,255,255, .4);
    border-radius: 10px;
    text-align: center;
    backdrop-filter: blur(5px);
    border-color: #FFF;
    border-width: 2px;
    box-shadow: 0 2px 4px rgba(22, 22, 22, 0.4);
  }

// ======================end shared================================= //
// ============ WebApp ============ //
.login-form {
    width: 400px;
    margin: 15% auto 0 auto;
    padding: $padding-medium;
    border-radius: $border-radius-small;
    box-shadow: $box-shadow-form;
}

.header-content {
    .language-switch {
        margin-top: $margin-small;

        svg {
            width: 15px;
            margin-top: $margin-small;

            &:hover {
                fill: $blue-light;
            }
        }
    }
}

.dashboard {
    .agencies-details {
        @include flex-layout($justify: space-evenly);
        flex-wrap: wrap;
        width: 100%;

        .agency-details-list {
            gap: 15px;
        }
    }
}

.supervision {
    .last-listen {
        width: 100%;
        margin: $margin-large;
        color: $blue-dark;
        text-align: end;
        font-weight: $bold;

        &-time {
            @include text-style('text-medium', inherit);
            font-weight: $bold;
        }
    }
    .monitoring {
        @include flex-layout($justify: space-between);
        width: 100%;
        padding: $padding-large;

        &-left-block {
            width: 70%;
        }

        &-right-block {
            @include flex-layout(flex, column, stretch, center);
        }
    }
    .last-record-infos {
        width: 330px;
        margin-right: $margin-medium;

        &-day, &-commune, &-hour {
          position: relative;
          left: 18px;
          margin: $margin-large 0;

          &::after {
            @include pseudo-element-after($blue-light);
          }
        }

        &-day::after {
          width: 50px;
          right: -25px;
          bottom: -15px;
          transform: rotate(45deg);
        }

        &-commune {
            @include text-ellipsis(90px);
            &::after {
                width: 100px;
                bottom: 0;
              }
          }

        &-hour {
            width: 80px;

            &::after {
                width: 40px;
                top: 0px;
                right: -25px;
                transform: rotate(-45deg);
            }
        }

        &-agency, &-mobile {
          text-align: center;
        }
        &-mobile {
          margin-top: $margin-small;
        }

        &-agency {
            @include text-ellipsis(200px)
        }
    }
    .indicator-cards {
        @include flex-layout($justify: space-between);

        .indicator-card {
            position: relative;
            @include box(220px);
            margin: $margin-medium;
            border: 1px solid $blue-light;
            border-radius: $border-radius-small;
            text-align: center;
            overflow: hidden;

            &-title {
                @include text-style-card('title-medium', $blue-light);
                margin: 0;
                padding: $padding-default;
                font-size: 18px;
                border-bottom: 1px solid $blue-light;
            }
            &-count {
                @include position(absolute, $top: 35%, $left: 50%);
                @include text-style-card('int-card', $blue-light);
                transform: translateX(-50%);
                color: $blue-light;
                font-weight: $bold;
            }
        }
    }
    .counter-cards {
        display: flex;
        flex-wrap: wrap;
        width: 250px;
        margin-top: $margin-small;
        gap: 20px;

        .counter-card {
            position: relative;
            @include box(115px);
            text-align: center;
            border-radius: $border-radius-small;
            box-shadow: $box-shadow-encart;
            background: $background-gradient-counter;
            color: $white-primary;

            &-title {
                @include text-style-card('title-card-default');
                margin: 0;
                padding: 5px 0 7px 0;
                border-bottom: 1px solid $white-primary;
            }

            &-count {
                @include position(absolute, $top: 40%, $left: 50%);
                @include text-style-card('int-card');
                transform: translateX(-50%);
                font-weight: $bold;
            }
        }
    }
    .chart-cards {
        @include flex-layout($justify: space-between);
        flex-wrap: wrap;
        width: 100%;
    }
}

.records-list {
    margin-top: $margin-medium;
}

.timeline-tag-drawer .ant-drawer-body {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    p {
        margin: 2px 0;
    }

    .timeline-tag {
        width: 40%;
    }
  }


.blockchain-popover {
    padding: $padding-large;

    .copy-blockchain-btn {
        @include position(absolute, $top: 12px, $right: 20px);
    }    
}

.settings {
    &-item {
        @include flex-layout('flex-center');
        @include box(250px, 70px);
        @include text-style('text-default');
        text-align: center;
        border-radius: $border-radius-medium;
        margin: $margin-medium;
        padding: $padding-default;
        box-shadow: $box-shadow-encart;
        background-color: $white-primary;
        cursor: pointer;

        &-picto {
            @include inline-block-middle;
            @include text-style-card('icon-medium');
            height: 40px;
            margin-right: $margin-large;
            line-height: 45px;
        }
    }
}

.orders-drawer .ant-drawer-header {
    border-bottom: none;
    padding: $padding-medium 0 0 $padding-large;
}

.order-form .ant-picker-range {
    width: 100%;
}

.editable-cell {
    margin: 0;
}

.review-order {
    width: 47%;
    margin: auto;
    text-align: center;
    margin-top: $margin-large;
  
    &-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: $margin-medium 0;
  
      &-content {
        display: flex;
        justify-content: space-between;
        width: 100%;
      }
    }
 
    .ant-divider {
      margin: $margin-medium 0;
    }
  }
 
// ============ end WebApp ============ //
// ============ Antd ============ //
.ant-select-selection-item {
    @include text-style('text-default');
    margin: 2px 11px 0;
}

.search-input-filter {
    display: block;
    margin-bottom: $margin-small;
}

.ant-table-expanded-row .ant-table-wrapper .ant-table-thead th.ant-table-cell {
    background: $blue-powder;
}

.ant-form-item-explain {
    margin: $margin-large 0;
}

.ant-progress-outer {
    @include position($position: absolute, $top: -8px)
}

.ant-progress .ant-progress-outer .ant-progress-inner {
    background-color: transparent;
}

.ant-input-number {
    width: 50px;
}

.ant-layout-footer {
    @include position ($position: fixed, $bottom: 0, $left: 0);
    @include box(100%, 80px);
    padding: 0;
    background-color: transparent;
    z-index: 300;
  }

  // ============ end Antd ============ //
  // =========== Keyframe ============= //
.bounce {
    animation: bounce 1s infinite both;
    transform-origin: center bottom;
}

@keyframes bounce {
    0%, 20%, 53%, 80%, 100% {
        transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
        top: 0;
    }
    40%, 43% {
        transition-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
        top: -6px
    }
    70% {
        transition-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
        top: 2px;
    }
    90% {
        top: 1px;
    }
}
  // =========== end Keyframe ============= //