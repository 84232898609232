// Global colors
$white-primary: #FFFFFF;
$white-light: #f5f5f5;
$blue-light: #19ACFB;
$blue-azure: #007FFF;
$blue-dark: #1376B3;
$blue-powder: #B0E0E6;
$orange-primary: #EAA74C;
$red-primary: #E60000;
$green-primary: #00B14E;
$grey-blue: #7794b3;
$black: #000000;
$lightGrey: #b3b3b3;

// Gradient counter card
$gradient-counter-hex-1: #3b94b4;
$gradient-counter-hex-2: #3997b8;
$gradient-counter-hex-3: #2daddb;
$gradient-counter-hex-4: #29b4e6;
$background-gradient-counter: linear-gradient($gradient-counter-hex-1, $gradient-counter-hex-2, $gradient-counter-hex-3, $gradient-counter-hex-4);

// Font size
$font-size-default: 14px;
$font-size-medium: 20px;
$font-size-large: 28px;

// Font weight
$bold: 700;

// Opacity
$black-opacity-02: rgba(0, 0, 0, 0.2);
$black-opacity-03: rgba(0, 0, 0, 0.3);

// Box shadow
$box-shadow-form: 0 0 10px 0 $black-opacity-02;
$box-shadow-encart: 3px 2px 5px $black-opacity-03;

// Border radius
$border-radius-small: 10px;
$border-radius-medium: 15px;
$border-radius-large: 25px;

// Margin
$margin-small: 5px;
$margin-medium: 10px;
$margin-large: 20px;

// Padding
$padding-default: 10px;
$padding-medium: 15px;
$padding-large: 20px;