@mixin flex-layout($display: flex, $direction: row, $justify: stretch, $align: stretch){
    @if ($display == 'flex-column'){
        display: flex;
        flex-direction: column;
    } @else if ($display == 'flex-center'){
        display: flex;
        justify-content: center;
        align-items: center;
    } @else if ($display == 'flex-center-column'){
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    } @else if ($display == 'flex-center-vert'){
        display: flex;
        align-items: center;
    } @else {
        display: $display;
        flex-direction: $direction;
        justify-content: $justify;
        align-items: $align;
    }
}

@mixin box($width, $height: $width){
    width: $width;
    height: $height;
}

@mixin text-ellipsis($width) {
    width: $width;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

@mixin position($position, $top: null, $right: null, $bottom: null, $left: null) {
    position: $position;
    top: $top;
    right: $right;
    bottom: $bottom;
    left: $left;
  }

@mixin pseudo-element-after($backgroundColor){
    position: absolute;
    display: block;
    content: '';
    height: 1px;
    background-color: $backgroundColor;
}

@mixin text-style-card($type, $color: $white-primary){
    @if($type == 'title-default'){
        font-size: $font-size-default;
        color: $color;
    } @else if($type == 'title-medium') {
        font-size: $font-size-medium;
        color: $color;
    } @else if($type == 'int-card'){
        font-size: 42px;
        color: $color;
    }
}

@mixin text-style($type, $color: $black){
    @if($type == 'icon-medium'){
        font-size: $font-size-medium;
        color: $color;
    } @else if($type == 'text-default'){
        font-size: $font-size-default;
        color: $color;
    } @else if($type == 'text-medium'){
        font-size: $font-size-medium;
        color: $color;
    } @else if($type == 'text-large'){
        font-size: $font-size-large;
        color: $color;
    }
}

@mixin inline-block-middle {
    display: inline-block;
    vertical-align: middle;
}

@mixin button-reset {
    border: none;
    background-color: transparent;
}